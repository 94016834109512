import { useUpdateOrganizationProposalSentEmailTextMutation } from "generated/graphql";
import getLocalString from "constants/Localization";
import useBitumioMutation from "hooks/useBitumioMutation";

export const useUpdateOrganizationProposalSentEmailText = () => {
  const [mutation, { loading, error, data }] = useUpdateOrganizationProposalSentEmailTextMutation({
    refetchQueries: ["getOrganization"],
    fetchPolicy: "network-only",
  });

  const [updateProposalSentEmailText, context] = useBitumioMutation(
    {
      mutation,
      loading,
      data,
      error,
      key: "updateOrganizationProposalSentEmailText",
    },
    {
      successMessage: getLocalString("organization.update-proposal-sent-email-text-success"),
      failureMessage: getLocalString("organization.update-proposal-sent-email-text-failure"),
    }
  );

  return [updateProposalSentEmailText, context] as const;
};
