import { Checkbox } from "@mui/material";
import { UpdateRolesWithPermissionsInputSchema } from "DDD/action-objects/RolesWithPermissionsUpdate";
import MDBox from "components/MDBox";
import { UserPermissions } from "generated/graphql";
import { useState } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";

interface PermissionFieldProps {
  arrayFieldName?: keyof UpdateRolesWithPermissionsInputSchema;
}

const PermissionFields: React.FC<PermissionFieldProps> = ({ arrayFieldName = "admin" }) => {
  const {
    register,
    watch,
    reset,
    formState: { errors },
  } = useFormContext<UpdateRolesWithPermissionsInputSchema>();

  const { fields } = useFieldArray({
    name: arrayFieldName,
  });

  const values = watch(arrayFieldName);
  const [manageCompaniesChecked, setManageCompaniesChecked] = useState(
    values.some((x) => x === UserPermissions.MANAGE_COMPANIES)
  );
  const [checkedOpportunities, setCheckedOpportunities] = useState(
    values.some((x) => x === UserPermissions.MANAGE_OPPORTUNITIES)
  );
  const [checkedProposals, setCheckedProposals] = useState(
    values.some((x) => x === UserPermissions.MANAGE_PROPOSALS)
  );

  const [checkedJobSchedule, setCheckedJobSchedule] = useState(
    values.some((x) => x === UserPermissions.MANAGE_SCHEDULE)
  );

  const [checkedViewAllJobs, setCheckedViewAllJobs] = useState(
    values.some((x) => x === UserPermissions.VIEW_ALL_JOBS)
  );
  const [checkedViewCrewJobs, setCheckedViewCrewJobs] = useState(
    values.some((x) => x === UserPermissions.VIEW_CREW_JOBS)
  );
  const [checkedViewAssignedJobs, setCheckedViewAssignedJobs] = useState(
    values.some((x) => x === UserPermissions.VIEW_ASSIGNED_JOBS)
  );

  const [checkedViewTimeTracking, setCheckedViewTimeTracking] = useState(
    values.some((x) => x === UserPermissions.VIEW_TIME_TRACKING)
  );

  const [checkedManageTimeTracking, setCheckedManageTimeTracking] = useState(
    values.some((x) => x === UserPermissions.MANAGE_TIME_TRACKING)
  );

  const [checkedAddManualTime, setCheckedAddManualTime] = useState(
    values.some((x) => x === UserPermissions.ADD_MANUAL_TIME)
  );

  return (
    <MDBox display="flex" flexDirection="column">
      <MDBox
        display="flex"
        justifyContent="center"
        bgColor={
          arrayFieldName === "admin" ||
          arrayFieldName === "employee" ||
          arrayFieldName === "subcontractor"
            ? "#f5f5f5"
            : "transparent"
        }
      >
        <Checkbox
          value={UserPermissions.VIEW_DASHBOARD}
          {...register(`${arrayFieldName}`)}
          defaultChecked={values.some((x) => x === UserPermissions.VIEW_DASHBOARD)}
          disabled={
            arrayFieldName === "admin" ||
            arrayFieldName === "employee" ||
            arrayFieldName === "subcontractor"
          }
        />
      </MDBox>
      <MDBox
        display="flex"
        justifyContent="center"
        bgColor={
          arrayFieldName === "admin" ||
          arrayFieldName === "employee" ||
          arrayFieldName === "subcontractor"
            ? "#f5f5f5"
            : "transparent"
        }
      >
        <Checkbox
          value={UserPermissions.VIEW_MAPS}
          {...register(`${arrayFieldName}`)}
          defaultChecked={values.some((x) => x === UserPermissions.VIEW_MAPS)}
          disabled={
            arrayFieldName === "admin" ||
            arrayFieldName === "employee" ||
            arrayFieldName === "subcontractor"
          }
        />
      </MDBox>
      <MDBox
        py={1.5}
        bgColor={
          arrayFieldName === "admin" ||
          arrayFieldName === "employee" ||
          arrayFieldName === "subcontractor"
            ? "#f5f5f5"
            : "transparent"
        }
      />
      <MDBox
        pt={1.5}
        display="flex"
        justifyContent="center"
        bgColor={
          arrayFieldName === "admin" ||
          arrayFieldName === "employee" ||
          arrayFieldName === "subcontractor"
            ? "#f5f5f5"
            : "transparent"
        }
      >
        <Checkbox
          id={`view_companies_${arrayFieldName}`}
          {...register(`${arrayFieldName}`)}
          value={UserPermissions.VIEW_COMPANIES}
          onChange={(e) => {
            const el = document.querySelector(
              `#manage_companies_${arrayFieldName}`
            ) as HTMLInputElement;

            if (!e.target.checked) {
              setManageCompaniesChecked(false);
              el.disabled = true;
            } else {
              el.disabled = false;
            }
          }}
          defaultChecked={values?.some((x) => x === UserPermissions.VIEW_COMPANIES)}
          disabled={
            arrayFieldName === "admin" ||
            arrayFieldName === "employee" ||
            arrayFieldName === "subcontractor"
          }
        />
      </MDBox>
      <MDBox
        display="flex"
        justifyContent="center"
        bgColor={
          arrayFieldName === "admin" ||
          arrayFieldName === "employee" ||
          arrayFieldName === "subcontractor"
            ? "#f5f5f5"
            : "transparent"
        }
      >
        <Checkbox
          {...register(`${arrayFieldName}`)}
          id={`manage_companies_${arrayFieldName}`}
          value={UserPermissions.MANAGE_COMPANIES}
          checked={manageCompaniesChecked}
          onChange={() => setManageCompaniesChecked(!manageCompaniesChecked)}
          disabled={
            arrayFieldName === "admin" ||
            arrayFieldName === "employee" ||
            arrayFieldName === "subcontractor"
          }
        />
      </MDBox>

      <MDBox
        py={1.5}
        bgColor={
          arrayFieldName === "admin" ||
          arrayFieldName === "employee" ||
          arrayFieldName === "subcontractor"
            ? "#f5f5f5"
            : "transparent"
        }
      />
      <MDBox
        pt={1.5}
        display="flex"
        justifyContent="center"
        bgColor={
          arrayFieldName === "admin" ||
          arrayFieldName === "employee" ||
          arrayFieldName === "subcontractor"
            ? "#f5f5f5"
            : "transparent"
        }
      >
        <Checkbox
          id={`view_opportunities_${arrayFieldName}`}
          value={UserPermissions.VIEW_OPPORTUNITIES}
          {...register(`${arrayFieldName}`)}
          onChange={(e) => {
            const el = document.querySelector(
              `#manage_opportunities_${arrayFieldName}`
            ) as HTMLInputElement;
            if (!e.target.checked) {
              setCheckedOpportunities(false);
              el.disabled = true;
            } else {
              el.disabled = false;
            }
          }}
          defaultChecked={values.some((x) => x === UserPermissions.VIEW_OPPORTUNITIES)}
          disabled={
            arrayFieldName === "admin" ||
            arrayFieldName === "employee" ||
            arrayFieldName === "subcontractor"
          }
        />
      </MDBox>
      <MDBox
        display="flex"
        justifyContent="center"
        bgColor={
          arrayFieldName === "admin" ||
          arrayFieldName === "employee" ||
          arrayFieldName === "subcontractor"
            ? "#f5f5f5"
            : "transparent"
        }
      >
        <Checkbox
          id={`manage_opportunities_${arrayFieldName}`}
          value={UserPermissions.MANAGE_OPPORTUNITIES}
          {...register(`${arrayFieldName}`)}
          checked={checkedOpportunities}
          onChange={() => setCheckedOpportunities(!checkedOpportunities)}
          disabled={
            !values.some((x) => x === UserPermissions.VIEW_OPPORTUNITIES) ||
            arrayFieldName === "admin" ||
            arrayFieldName === "employee" ||
            arrayFieldName === "subcontractor"
          }
        />
      </MDBox>

      <MDBox
        py={1.5}
        bgColor={
          arrayFieldName === "admin" ||
          arrayFieldName === "employee" ||
          arrayFieldName === "subcontractor"
            ? "#f5f5f5"
            : "transparent"
        }
      />
      <MDBox
        pt={1.5}
        display="flex"
        justifyContent="center"
        bgColor={
          arrayFieldName === "admin" ||
          arrayFieldName === "employee" ||
          arrayFieldName === "subcontractor"
            ? "#f5f5f5"
            : "transparent"
        }
      >
        <Checkbox
          id={`view_proposals_${arrayFieldName}`}
          value={UserPermissions.VIEW_ALL_PROPOSALS}
          {...register(`${arrayFieldName}`)}
          defaultChecked={values.some((x) => x === UserPermissions.VIEW_ALL_PROPOSALS)}
          onChange={(e) => {
            const assignProposal = document.querySelector(
              `#assigned_proposals_${arrayFieldName}`
            ) as HTMLInputElement;
            const manageProposals = document.querySelector(
              `#manage_proposals_${arrayFieldName}`
            ) as HTMLInputElement;
            if (!e.target.checked && !assignProposal.checked) {
              manageProposals.disabled = true;
              setCheckedProposals(false);
            } else {
              manageProposals.disabled = false;
            }
          }}
          disabled={
            arrayFieldName === "admin" ||
            arrayFieldName === "employee" ||
            arrayFieldName === "subcontractor"
          }
        />
      </MDBox>
      <MDBox
        display="flex"
        justifyContent="center"
        bgColor={
          arrayFieldName === "admin" ||
          arrayFieldName === "employee" ||
          arrayFieldName === "subcontractor"
            ? "#f5f5f5"
            : "transparent"
        }
      >
        <Checkbox
          id={`assigned_proposals_${arrayFieldName}`}
          value={UserPermissions.VIEW_ONLY_ASSIGNED_PROPOSALS}
          {...register(`${arrayFieldName}`)}
          onChange={(e) => {
            const viewProposal = document.querySelector(
              `#view_proposals_${arrayFieldName}`
            ) as HTMLInputElement;
            const manageProposals = document.querySelector(
              `#manage_proposals_${arrayFieldName}`
            ) as HTMLInputElement;
            if (!e.target.checked && !viewProposal.checked) {
              manageProposals.disabled = true;
              setCheckedProposals(false);
            } else {
              manageProposals.disabled = false;
            }
          }}
          defaultChecked={values.some((x) => x === UserPermissions.VIEW_ONLY_ASSIGNED_PROPOSALS)}
          disabled={
            arrayFieldName === "admin" ||
            arrayFieldName === "employee" ||
            arrayFieldName === "subcontractor"
          }
        />
      </MDBox>
      <MDBox
        display="flex"
        justifyContent="center"
        bgColor={
          arrayFieldName === "admin" ||
          arrayFieldName === "employee" ||
          arrayFieldName === "subcontractor"
            ? "#f5f5f5"
            : "transparent"
        }
      >
        <Checkbox
          id={`manage_proposals_${arrayFieldName}`}
          value={UserPermissions.MANAGE_PROPOSALS}
          {...register(`${arrayFieldName}`)}
          checked={checkedProposals}
          onChange={() => setCheckedProposals(!checkedProposals)}
          disabled={
            arrayFieldName === "admin" ||
            arrayFieldName === "employee" ||
            arrayFieldName === "subcontractor"
          }
        />
      </MDBox>
      <MDBox
        py={1.5}
        bgColor={
          arrayFieldName === "admin" ||
          arrayFieldName === "employee" ||
          arrayFieldName === "subcontractor"
            ? "#f5f5f5"
            : "transparent"
        }
      />
      <MDBox
        pt={1.5}
        display="flex"
        justifyContent="center"
        bgColor={
          arrayFieldName === "admin" || arrayFieldName === "subcontractor"
            ? "#f5f5f5"
            : "transparent"
        }
      >
        <Checkbox
          value={UserPermissions.VIEW_ALL_JOBS}
          {...register(`${arrayFieldName}`)}
          checked={checkedViewAllJobs}
          onChange={(e) => {
            setCheckedViewAllJobs(!checkedViewAllJobs);
            if (e.target.checked) {
              setCheckedViewCrewJobs(true);
              setCheckedViewAssignedJobs(true);
            }
          }}
          disabled={arrayFieldName === "admin" || arrayFieldName === "subcontractor"}
        />
      </MDBox>
      <MDBox
        display="flex"
        justifyContent="center"
        bgColor={
          arrayFieldName === "admin" || arrayFieldName === "subcontractor"
            ? "#f5f5f5"
            : "transparent"
        }
      >
        <Checkbox
          value={UserPermissions.VIEW_CREW_JOBS}
          {...register(`${arrayFieldName}`)}
          checked={checkedViewCrewJobs}
          onChange={(e) => {
            setCheckedViewCrewJobs(!checkedViewCrewJobs);
            if (e.target.checked) {
              setCheckedViewAllJobs(false);
            }
          }}
          disabled={arrayFieldName === "admin" || arrayFieldName === "subcontractor"}
        />
      </MDBox>
      <MDBox
        display="flex"
        justifyContent="center"
        bgColor={arrayFieldName === "admin" ? "#f5f5f5" : "transparent"}
      >
        <Checkbox
          value={UserPermissions.VIEW_ASSIGNED_JOBS}
          {...register(`${arrayFieldName}`)}
          checked={checkedViewAssignedJobs}
          onChange={(e) => {
            setCheckedViewAssignedJobs(!checkedViewAssignedJobs);
            if (e.target.checked && !checkedViewAllJobs && !checkedViewCrewJobs) {
              setCheckedViewAllJobs(false);
              setCheckedViewCrewJobs(false);
            }
          }}
          disabled={arrayFieldName === "admin"}
        />
      </MDBox>
      <MDBox
        display="flex"
        justifyContent="center"
        bgColor={
          arrayFieldName === "admin" ||
          arrayFieldName === "employee" ||
          arrayFieldName === "subcontractor"
            ? "#f5f5f5"
            : "transparent"
        }
      >
        <Checkbox
          value={UserPermissions.VIEW_JOB_INFO}
          {...register(`${arrayFieldName}`)}
          defaultChecked={values.some((x) => x === UserPermissions.VIEW_JOB_INFO)}
          disabled={
            arrayFieldName === "admin" ||
            arrayFieldName === "employee" ||
            arrayFieldName === "subcontractor"
          }
        />
      </MDBox>
      <MDBox
        display="flex"
        justifyContent="center"
        bgColor={
          arrayFieldName === "admin" ||
          arrayFieldName === "employee" ||
          arrayFieldName === "subcontractor"
            ? "#f5f5f5"
            : "transparent"
        }
      >
        <Checkbox
          value={UserPermissions.PUSH_JOB_TO_QUICKBOOKS}
          {...register(`${arrayFieldName}`)}
          defaultChecked={values.some((x) => x === UserPermissions.PUSH_JOB_TO_QUICKBOOKS)}
          disabled={
            arrayFieldName === "admin" ||
            arrayFieldName === "employee" ||
            arrayFieldName === "subcontractor"
          }
        />
      </MDBox>
      <MDBox
        display="flex"
        justifyContent="center"
        bgColor={
          arrayFieldName === "admin" ||
          arrayFieldName === "employee" ||
          arrayFieldName === "subcontractor"
            ? "#f5f5f5"
            : "transparent"
        }
      >
        <Checkbox
          value={UserPermissions.VIEW_JOB_FINANCIAL_SUMMARIES}
          {...register(`${arrayFieldName}`)}
          defaultChecked={values.some((x) => x === UserPermissions.VIEW_JOB_FINANCIAL_SUMMARIES)}
          disabled={
            arrayFieldName === "admin" ||
            arrayFieldName === "employee" ||
            arrayFieldName === "subcontractor"
          }
        />
      </MDBox>
      <MDBox
        py={1.5}
        bgColor={
          arrayFieldName === "admin" ||
          arrayFieldName === "employee" ||
          arrayFieldName === "subcontractor"
            ? "#f5f5f5"
            : "transparent"
        }
      />
      <MDBox
        pt={1.5}
        display="flex"
        justifyContent="center"
        bgColor={
          arrayFieldName === "admin" ||
          arrayFieldName === "employee" ||
          arrayFieldName === "subcontractor"
            ? "#f5f5f5"
            : "transparent"
        }
      >
        <Checkbox
          value={UserPermissions.VIEW_WORK_ORDER}
          {...register(`${arrayFieldName}`)}
          defaultChecked={values.some((x) => x === UserPermissions.VIEW_WORK_ORDER)}
          disabled={
            arrayFieldName === "admin" ||
            arrayFieldName === "employee" ||
            arrayFieldName === "subcontractor"
          }
        />
      </MDBox>
      <MDBox
        display="flex"
        justifyContent="center"
        bgColor={arrayFieldName === "admin" ? "#f5f5f5" : "transparent"}
      >
        <Checkbox
          value={UserPermissions.INPUT_JOB_INFO_COSTS}
          {...register(`${arrayFieldName}`)}
          defaultChecked={values.some((x) => x === UserPermissions.INPUT_JOB_INFO_COSTS)}
          disabled={arrayFieldName === "admin"}
        />
      </MDBox>
      <MDBox
        py={1.5}
        bgColor={
          arrayFieldName === "admin" ||
          arrayFieldName === "employee" ||
          arrayFieldName === "subcontractor"
            ? "#f5f5f5"
            : "transparent"
        }
      />
      <MDBox
        pt={1.5}
        display="flex"
        justifyContent="center"
        bgColor={
          arrayFieldName === "admin" ||
          arrayFieldName === "employee" ||
          arrayFieldName === "subcontractor"
            ? "#f5f5f5"
            : "transparent"
        }
      >
        <Checkbox
          value={UserPermissions.VIEW_SCHEDULE}
          {...register(`${arrayFieldName}`)}
          onChange={(e) => {
            const el = document.querySelector(
              `#manage_schedule_${arrayFieldName}`
            ) as HTMLInputElement;
            if (!e.target.checked) {
              el.disabled = true;
              setCheckedJobSchedule(false);
            } else {
              el.disabled = false;
            }
          }}
          defaultChecked={values.some((x) => x === UserPermissions.VIEW_SCHEDULE)}
          disabled={
            arrayFieldName === "admin" ||
            arrayFieldName === "employee" ||
            arrayFieldName === "subcontractor"
          }
        />
      </MDBox>
      <MDBox
        display="flex"
        justifyContent="center"
        bgColor={
          arrayFieldName === "admin" ||
          arrayFieldName === "employee" ||
          arrayFieldName === "subcontractor"
            ? "#f5f5f5"
            : "transparent"
        }
      >
        <Checkbox
          id={`manage_schedule_${arrayFieldName}`}
          value={UserPermissions.MANAGE_SCHEDULE}
          {...register(`${arrayFieldName}`)}
          checked={checkedJobSchedule}
          onChange={(e) => setCheckedJobSchedule(!checkedJobSchedule)}
          disabled={
            arrayFieldName === "admin" ||
            arrayFieldName === "employee" ||
            arrayFieldName === "subcontractor"
          }
        />
      </MDBox>

      <MDBox
        display="flex"
        justifyContent="center"
        bgColor={
          arrayFieldName === "admin" ||
          arrayFieldName === "employee" ||
          arrayFieldName === "subcontractor"
            ? "#f5f5f5"
            : "transparent"
        }
      >
        <Checkbox
          value={UserPermissions.MANAGE_PRODUCT_SETTINGS}
          {...register(`${arrayFieldName}`)}
          defaultChecked={values.some((x) => x === UserPermissions.MANAGE_PRODUCT_SETTINGS)}
          disabled={
            arrayFieldName === "admin" ||
            arrayFieldName === "employee" ||
            arrayFieldName === "subcontractor"
          }
        />
      </MDBox>
      <MDBox
        py={1.5}
        bgColor={
          arrayFieldName === "admin" ||
          arrayFieldName === "employee" ||
          arrayFieldName === "subcontractor"
            ? "#f5f5f5"
            : "transparent"
        }
      />
      <MDBox
        pt={1.5}
        display="flex"
        justifyContent="center"
        bgColor={
          arrayFieldName === "admin" ||
          arrayFieldName === "employee" ||
          arrayFieldName === "subcontractor"
            ? "#f5f5f5"
            : "transparent"
        }
      >
        <Checkbox
          value={UserPermissions.MANAGE_USERS}
          {...register(`${arrayFieldName}`)}
          defaultChecked={values.some((x) => x === UserPermissions.MANAGE_USERS)}
          disabled={
            arrayFieldName === "admin" ||
            arrayFieldName === "employee" ||
            arrayFieldName === "subcontractor"
          }
        />
      </MDBox>
      <MDBox
        display="flex"
        justifyContent="center"
        bgColor={
          arrayFieldName === "admin" ||
          arrayFieldName === "employee" ||
          arrayFieldName === "subcontractor"
            ? "#f5f5f5"
            : "transparent"
        }
      >
        <Checkbox
          value={UserPermissions.DO_DATA_IMPORTS}
          {...register(`${arrayFieldName}`)}
          defaultChecked={values.some((x) => x === UserPermissions.DO_DATA_IMPORTS)}
          disabled={
            arrayFieldName === "admin" ||
            arrayFieldName === "employee" ||
            arrayFieldName === "subcontractor"
          }
        />
      </MDBox>
      <MDBox
        display="flex"
        justifyContent="center"
        bgColor={
          arrayFieldName === "admin" ||
          arrayFieldName === "employee" ||
          arrayFieldName === "subcontractor"
            ? "#f5f5f5"
            : "transparent"
        }
      >
        <Checkbox
          value={UserPermissions.MANAGE_NOTIFICATION_TRIGGERS}
          {...register(`${arrayFieldName}`)}
          defaultChecked={values.some((x) => x === UserPermissions.MANAGE_NOTIFICATION_TRIGGERS)}
          disabled={
            arrayFieldName === "admin" ||
            arrayFieldName === "employee" ||
            arrayFieldName === "subcontractor"
          }
        />
      </MDBox>
      <MDBox
        display="flex"
        justifyContent="center"
        bgColor={
          arrayFieldName === "admin" ||
          arrayFieldName === "employee" ||
          arrayFieldName === "subcontractor"
            ? "#f5f5f5"
            : "transparent"
        }
      >
        <Checkbox
          value={UserPermissions.MANAGE_PROPOSAL_SETTINGS}
          {...register(`${arrayFieldName}`)}
          defaultChecked={values.some((x) => x === UserPermissions.MANAGE_PROPOSAL_SETTINGS)}
          disabled={
            arrayFieldName === "admin" ||
            arrayFieldName === "employee" ||
            arrayFieldName === "subcontractor"
          }
        />
      </MDBox>
      <MDBox
        display="flex"
        justifyContent="center"
        bgColor={
          arrayFieldName === "admin" ||
          arrayFieldName === "employee" ||
          arrayFieldName === "subcontractor"
            ? "#f5f5f5"
            : "transparent"
        }
      >
        <Checkbox
          value={UserPermissions.MANAGE_ORGANIZATION_INFO}
          {...register(`${arrayFieldName}`)}
          defaultChecked={values.some((x) => x === UserPermissions.MANAGE_ORGANIZATION_INFO)}
          disabled={
            arrayFieldName === "admin" ||
            arrayFieldName === "employee" ||
            arrayFieldName === "subcontractor"
          }
        />
      </MDBox>
      <MDBox
        display="flex"
        justifyContent="center"
        bgColor={
          arrayFieldName === "admin" ||
          arrayFieldName === "employee" ||
          arrayFieldName === "subcontractor"
            ? "#f5f5f5"
            : "transparent"
        }
      >
        <Checkbox
          value={UserPermissions.MANAGE_SUBSCRIPTION}
          {...register(`${arrayFieldName}`)}
          defaultChecked={values.some((x) => x === UserPermissions.MANAGE_SUBSCRIPTION)}
          disabled={
            arrayFieldName === "admin" ||
            arrayFieldName === "employee" ||
            arrayFieldName === "subcontractor"
          }
        />
      </MDBox>
      <MDBox
        display="flex"
        justifyContent="center"
        bgColor={
          arrayFieldName === "admin" ||
          arrayFieldName === "employee" ||
          arrayFieldName === "subcontractor"
            ? "#f5f5f5"
            : "transparent"
        }
      >
        <Checkbox
          value={UserPermissions.MANAGE_ORGANIZATION_INFO}
          {...register(`${arrayFieldName}`)}
          defaultChecked={values.some((x) => x === UserPermissions.MANAGE_EMAIL_SETTINGS)}
          disabled={
            arrayFieldName === "admin" ||
            arrayFieldName === "employee" ||
            arrayFieldName === "subcontractor"
          }
        />
      </MDBox>
      <MDBox
        display="flex"
        justifyContent="center"
        bgColor={
          arrayFieldName === "admin" ||
          arrayFieldName === "employee" ||
          arrayFieldName === "subcontractor"
            ? "#f5f5f5"
            : "transparent"
        }
      >
        <Checkbox
          value={UserPermissions.MANAGE_EMPLOYEES}
          {...register(`${arrayFieldName}`)}
          defaultChecked={values.some((x) => x === UserPermissions.MANAGE_EMPLOYEES)}
          disabled={
            arrayFieldName === "admin" ||
            arrayFieldName === "employee" ||
            arrayFieldName === "subcontractor"
          }
        />
      </MDBox>
      <MDBox
        display="flex"
        justifyContent="center"
        bgColor={
          arrayFieldName === "admin" ||
          arrayFieldName === "employee" ||
          arrayFieldName === "subcontractor"
            ? "#f5f5f5"
            : "transparent"
        }
      >
        <Checkbox
          value={UserPermissions.GENERATE_REPORTS}
          {...register(`${arrayFieldName}`)}
          defaultChecked={values.some((x) => x === UserPermissions.GENERATE_REPORTS)}
          disabled={
            arrayFieldName === "admin" ||
            arrayFieldName === "employee" ||
            arrayFieldName === "subcontractor"
          }
        />
      </MDBox>
      <MDBox
        display="flex"
        justifyContent="center"
        bgColor={
          arrayFieldName === "admin" ||
          arrayFieldName === "employee" ||
          arrayFieldName === "subcontractor"
            ? "#f5f5f5"
            : "transparent"
        }
      >
        <Checkbox
          value={UserPermissions.MANAGE_LINKED_SERVICES}
          {...register(`${arrayFieldName}`)}
          defaultChecked={values.some((x) => x === UserPermissions.MANAGE_LINKED_SERVICES)}
          disabled={
            arrayFieldName === "admin" ||
            arrayFieldName === "employee" ||
            arrayFieldName === "subcontractor"
          }
        />
      </MDBox>
      <MDBox
        display="flex"
        justifyContent="center"
        bgColor={
          arrayFieldName === "admin" ||
          arrayFieldName === "employee" ||
          arrayFieldName === "subcontractor"
            ? "#f5f5f5"
            : "transparent"
        }
      >
        <Checkbox
          value={UserPermissions.MANAGE_KNOWLEDGE_BASE}
          {...register(`${arrayFieldName}`)}
          defaultChecked={values.some((x) => x === UserPermissions.MANAGE_KNOWLEDGE_BASE)}
          disabled={
            arrayFieldName === "admin" ||
            arrayFieldName === "employee" ||
            arrayFieldName === "subcontractor"
          }
        />
      </MDBox>
      <MDBox
        py={1.5}
        bgColor={
          arrayFieldName === "admin" ||
          arrayFieldName === "employee" ||
          arrayFieldName === "subcontractor"
            ? "#f5f5f5"
            : "transparent"
        }
      />
      <MDBox
        display="flex"
        justifyContent="center"
        bgColor={arrayFieldName === "admin" ? "#f5f5f5" : "transparent"}
      >
        <Checkbox
          value={UserPermissions.VIEW_TIME_TRACKING}
          {...register(`${arrayFieldName}`)}
          defaultChecked={values.some((x) => x === UserPermissions.VIEW_TIME_TRACKING)}
          onChange={(e) => setCheckedViewTimeTracking(e.target.checked)}
          disabled={arrayFieldName === "admin"}
        />
      </MDBox>
      <MDBox
        display="flex"
        justifyContent="center"
        bgColor={
          arrayFieldName === "admin" ||
          arrayFieldName === "employee" ||
          arrayFieldName === "subcontractor"
            ? "#f5f5f5"
            : "transparent"
        }
      >
        <Checkbox
          value={UserPermissions.MANAGE_TIME_TRACKING}
          {...register(`${arrayFieldName}`)}
          defaultChecked={values.some((x) => x === UserPermissions.MANAGE_TIME_TRACKING)}
          onChange={(e) => setCheckedManageTimeTracking(e.target.checked)}
          disabled={
            arrayFieldName === "admin" ||
            arrayFieldName === "employee" ||
            arrayFieldName === "subcontractor"
          }
        />
      </MDBox>
      <MDBox
        display="flex"
        justifyContent="center"
        bgColor={
          arrayFieldName === "admin" || arrayFieldName === "subcontractor"
            ? "#f5f5f5"
            : "transparent"
        }
      >
        <Checkbox
          value={UserPermissions.ADD_MANUAL_TIME}
          {...register(`${arrayFieldName}`)}
          defaultChecked={values.some((x) => x === UserPermissions.ADD_MANUAL_TIME)}
          onChange={(e) => setCheckedAddManualTime(e.target.checked)}
          disabled={arrayFieldName === "admin" || arrayFieldName === "subcontractor"}
        />
      </MDBox>
    </MDBox>
  );
};

export default PermissionFields;
