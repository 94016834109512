import { zodResolver } from "@hookform/resolvers/zod";
import { Grid, FormHelperText } from "@mui/material";
import FormField from "components/FormField/FormField";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import WYSIWYG from "components/WYSIWYG/WYSIWYG";
import Modal from "modules/Modal/Modal";
import { Controller, useForm, FormProvider } from "react-hook-form";
import * as z from "zod";
import { Proposal, ProposalLayout } from "generated/graphql";
import { Recipients } from "./SendProposal";
import { useMemo } from "react";

const schema = z.object({
  emails: z.array(z.string().min(1, "Email is required")),
  subject: z.string().min(1, "Subject is required"),
  body: z.string().min(1, "Body is required"),
});

type FormValues = z.infer<typeof schema>;

interface ProposalConfirmModalProps {
  open: boolean;
  onClose: () => void;
  proposal: Proposal;
  emailBody: string;
  onSendProposal: (data: FormValues) => Promise<void>;
  onPreviewProposal: (data: FormValues) => Promise<void>;
  loading: boolean;
}

export default function ProposalConfirmationModal({
  open,
  onClose,
  proposal,
  emailBody,
  onSendProposal,
  onPreviewProposal,
  loading,
}: ProposalConfirmModalProps) {
  const defaultValues = useMemo(() => {
    return {
      emails: [proposal?.clientEmailOverride || proposal?.contact?.email || ""],
      subject:
        proposal?.emailSubjectOverride ||
        `${proposal?.company?.name} Proposal for ${proposal?.stages?.[0]?.name}`,
      body: proposal?.emailBodyOverride || emailBody,
    };
  }, [proposal]);

  const methods = useForm<FormValues>({
    resolver: zodResolver(schema),
    defaultValues,
    values: defaultValues,
  });

  const handleSendProposal = async (data: FormValues) => {
    await onSendProposal(data);
    onClose();
  };

  const handlePreviewProposal = async (data: FormValues) => {
    await onPreviewProposal(data);
    onClose();
  };

  return (
    <Modal open={open} onClose={onClose}>
      <FormProvider {...methods}>
        <MDBox
          component="form"
          role="form"
          onSubmit={methods.handleSubmit(handleSendProposal)}
          p={3}
        >
          <MDTypography variant="h5" mb={3}>
            Confirm Proposal Details
          </MDTypography>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={5} md={2}>
                  <MDTypography variant="body2" fontWeight="regular">
                    Company:
                  </MDTypography>
                </Grid>
                <Grid item xs={7} md={10}>
                  <MDTypography variant="body2" fontWeight="regular">
                    <strong>{proposal?.company?.name || "N/A"}</strong>
                  </MDTypography>
                </Grid>
              </Grid>
              <Grid container mt={1}>
                <Grid item xs={5} md={2}>
                  <MDTypography variant="body2" fontWeight="regular">
                    Contact:
                  </MDTypography>
                </Grid>
                <Grid item xs={7} md={10}>
                  <MDTypography variant="body2" fontWeight="regular">
                    <strong>
                      {proposal?.contact
                        ? (
                            (proposal.contact.firstName || "") +
                            " " +
                            (proposal.contact.lastName || "")
                          ).trim() || "N/A"
                        : "N/A"}
                    </strong>
                  </MDTypography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Recipients />
            </Grid>
            <Grid item xs={12}>
              <FormField
                label="Email Subject"
                {...methods.register("subject")}
                error={methods.formState.errors.subject}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                control={methods.control}
                name="body"
                render={({ field, fieldState: { error } }) => (
                  <>
                    <WYSIWYG
                      type="proposal"
                      content={field.value}
                      onChange={(content) => field.onChange(content)}
                      title="Email Body"
                      tooltip="This text will be included in the email when the proposal is sent."
                      onBlur={field.onBlur}
                      config={{
                        removePlugins: [
                          "EasyImage",
                          "ImageUpload",
                          "MediaEmbed",
                          "Table",
                          "TableToolbar",
                        ],
                      }}
                    />
                    {error && (
                      <FormHelperText
                        sx={{
                          color: "red",
                        }}
                      >
                        {error.message}
                      </FormHelperText>
                    )}
                  </>
                )}
              />
            </Grid>
          </Grid>
          <MDBox mt={4} mb={1} display="flex" justifyContent="space-between">
            <MDButton
              color="blue"
              onClick={methods.handleSubmit(handlePreviewProposal)}
              disabled={loading}
            >
              Preview Proposal
            </MDButton>
            <MDButton color="success" type="submit" disabled={loading}>
              Send Proposal
            </MDButton>
          </MDBox>
        </MDBox>
      </FormProvider>
    </Modal>
  );
}
