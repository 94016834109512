import AuthWall from "modules/AuthWall/AuthWall";
import Dashboard from "pages/index";
import TermsAndConditionsPage from "pages/terms-and-conditions";
import PublicMapPage from "pages/map/public-map";
import { Icon } from "@mui/material";
import MapPage from "pages/map/map";
import AuthWallSubscriber from "modules/AuthWallSubscriber/AuthWallSubscriber";
import DownloadAppPage from "pages/download-app";
import { UserPermissions } from "generated/graphql";
import NotAuthorizedPage from "components/NotAuthorizedPage";

const generalRoutes = [
  {
    name: "Dashboard",
    permission: UserPermissions.VIEW_DASHBOARD,
    key: "dashboard",
    route: "/",
    component: (
      <AuthWall permission={UserPermissions.VIEW_DASHBOARD}>
        <AuthWallSubscriber>
          <Dashboard />
        </AuthWallSubscriber>
      </AuthWall>
    ),
  },
  {
    name: "Terms and Conditions",
    key: "terms-and-conditions",
    route: "/pages/terms-and-conditions",
    component: <TermsAndConditionsPage />,
  },
  {
    type: "link",
    permission: UserPermissions.VIEW_MAPS,
    icon: <Icon fontSize="medium">map</Icon>,
    name: "Maps",
    key: "map.view-map",
    route: "/map",
    component: (
      <AuthWall permission={UserPermissions.VIEW_MAPS}>
        <AuthWallSubscriber>
          <MapPage />
        </AuthWallSubscriber>
      </AuthWall>
    ),
  },
  {
    type: "link",
    name: "Takeoffs",
    key: "takeoffs",
    route: "https://takeoffs.bitumio.com/",
    target: "_blank",
    href: "https://takeoffs.bitumio.com/",
    icon: <Icon fontSize="medium">article</Icon>,
  },
  {
    name: "Download App",
    title: "Download App",
    key: "download-app",
    route: "/download-app",
    component: <DownloadAppPage />,
  },
  {
    name: "Public Map",
    title: "Public Map",
    key: "public-map",
    route: "/public-map",
    component: <PublicMapPage />,
  },
  {
    name: "Not Authorized",
    title: "Not Authorized",
    key: "not-authorized",
    route: "/not-authorized",
    component: <NotAuthorizedPage />,
  },
] as const;

export default generalRoutes;
