import { zodResolver } from "@hookform/resolvers/zod";
import { Card, FormHelperText, Grid } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import WYSIWYG from "components/WYSIWYG/WYSIWYG";
import { Controller, useForm } from "react-hook-form";
import * as z from "zod";

interface ProposalSentEmailFormProps {
  proposalSentEmailText: string;
  loading: boolean;
  onSubmit: (text: string) => Promise<{ success: boolean; data: any }>;
}

const schema = z.object({
  text: z.string().min(1, "Email text cannot be empty"),
});

type FormValues = {
  text: string;
};

export default function ProposalSentEmailForm({
  proposalSentEmailText,
  loading,
  onSubmit,
}: ProposalSentEmailFormProps) {
  const methods = useForm<FormValues>({
    resolver: zodResolver(schema),
    defaultValues: { text: proposalSentEmailText },
  });

  return (
    <Card sx={{ p: "25px", mb: "20px" }}>
      <MDBox
        role="form"
        component="form"
        onSubmit={methods.handleSubmit(async ({ text }) => {
          await onSubmit(text);
        })}
      >
        <Controller
          control={methods.control}
          name="text"
          render={({ field, fieldState: { error } }) => (
            <>
              <WYSIWYG
                type="proposal"
                content={field.value}
                onChange={(content) => field.onChange(content)}
                title="Proposal Sent Email Text"
                tooltip="This text will be included in the email when a proposal is sent to a client."
                onBlur={field.onBlur}
              />
              {error && error?.message && (
                <FormHelperText
                  sx={{
                    color: "red",
                  }}
                >
                  {error.message}
                </FormHelperText>
              )}
            </>
          )}
        />
        <Grid item xs={12} lg={6}>
          <MDBox display="flex" justifyContent="flex-end" mt={2}>
            <MDButton variant="gradient" color="success" type="submit" disabled={loading}>
              Save
            </MDButton>
          </MDBox>
        </Grid>
      </MDBox>
    </Card>
  );
}
