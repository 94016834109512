import { zodResolver } from "@hookform/resolvers/zod";
import { Card, Checkbox, FormControlLabel, Grid } from "@mui/material";
import CountrySelect from "components/CountrySelect/CountrySelect";
import FormField from "components/FormField/FormField";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import StateSelect from "components/StateSelect/StateSelect";
import { schema, defaultValues } from "DDD/action-objects/AddressEdit";
import { CreateOrganizationAddressInput } from "generated/graphql";
import { useForm } from "react-hook-form";
import { useEffect, useRef, useState } from "react";
import { getAddressObject } from "helpers/map";

export default function Create({ onSubmit, loading }) {
  const [showAddressFields, setShowAddressFields] = useState<boolean>(false);
  const autoCompleteRef = useRef<google.maps.places.Autocomplete>();
  const geocoderRef = useRef<google.maps.Geocoder>();

  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
    control,
    setValue,
  } = useForm<CreateOrganizationAddressInput>({
    resolver: zodResolver(schema),
    defaultValues,
  });

  useEffect(() => {
    geocoderRef.current = new google.maps.Geocoder();

    autoCompleteRef.current = new google.maps.places.Autocomplete(
      document.getElementById("line1") as HTMLInputElement,
      {
        componentRestrictions: { country: ["USA", "CA"] },
        strictBounds: false,
        fields: ["address_components", "geometry"],
      }
    );

    if (autoCompleteRef.current) {
      autoCompleteRef.current.addListener("place_changed", async function () {
        const place = await autoCompleteRef.current.getPlace();
        if (place?.name) {
          setValue("line1", "");
          return;
        }

        const addressParts = getAddressObject(place.address_components);
        setValue("line1", `${addressParts.home} ${addressParts.street}`);
        setValue("city", addressParts.city);
        setValue("state", addressParts.region);
        setValue("country", addressParts.country);
        setValue("zip", addressParts.postal_code);
        setShowAddressFields(true);

        if (place.geometry?.location) {
          const lat = place.geometry.location.lat();
          const lng = place.geometry.location.lng();
          setValue("latitude", lat.toString());
          setValue("longitude", lng.toString());
        }
      });
    }
  }, [setValue]);

  const geocodeAddress = async (address: string) => {
    if (!geocoderRef.current) return;

    try {
      const result = await geocoderRef.current.geocode({ address });
      if (result.results[0]?.geometry?.location) {
        const lat = result.results[0].geometry.location.lat();
        const lng = result.results[0].geometry.location.lng();
        setValue("latitude", lat.toString());
        setValue("longitude", lng.toString());
      }
    } catch (error) {}
  };

  return (
    <Card>
      <MDBox
        p={3}
        component="form"
        role="form"
        onSubmit={handleSubmit(async (rest: CreateOrganizationAddressInput) => {
          if (!rest.latitude || !rest.longitude) {
            const fullAddress = `${rest.line1}, ${rest.city}, ${rest.state} ${rest.zip}, ${rest.country}`;
            await geocodeAddress(fullAddress);
          }

          const result = await onSubmit(rest);
          if (result.success) {
            reset();
            setShowAddressFields(false);
          }
        })}
      >
        <MDBox mt={1}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <FormField
                type="text"
                label="Address Line 1"
                placeholder="Address Line 1"
                error={errors.line1}
                id="line1"
                {...register("line1")}
              />
              {!showAddressFields && (
                <MDButton
                  sx={{
                    marginTop: "10px",
                  }}
                  variant="gradient"
                  color="secondary"
                  onClick={() => setShowAddressFields(true)}
                >
                  Manually set address
                </MDButton>
              )}
            </Grid>
            {showAddressFields && (
              <>
                <Grid item xs={12}>
                  <FormField
                    type="text"
                    label="Address Line 2"
                    placeholder="Address Line 2"
                    error={errors.line2}
                    {...register("line2")}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormField
                    type="text"
                    label="Address City"
                    placeholder="Address City"
                    error={errors.city}
                    {...register("city")}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormField
                    type="text"
                    label="Address State"
                    error={errors.state}
                    {...register("state")}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormField
                    type="text"
                    label="Address Zip"
                    placeholder="Address Zip"
                    error={errors.zip}
                    {...register("zip")}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <CountrySelect
                    name="country"
                    label="Address Country"
                    defaultValue="US"
                    required
                    control={control}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControlLabel
                    control={<Checkbox {...register("isPrimary")} />}
                    label="Primary Address"
                  />
                </Grid>
                <Grid item xs={12} display="flex" justifyContent="flex-end">
                  <MDButton type="submit" variant="gradient" color="success" disabled={loading}>
                    Create
                  </MDButton>
                </Grid>
              </>
            )}
          </Grid>
        </MDBox>
      </MDBox>
    </Card>
  );
}
