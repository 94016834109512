import Modal from "modules/Modal/Modal";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { useEffect } from "react";
import { Grid } from "@mui/material";

interface ViewLocationModalProps {
  open: boolean;
  onClose: () => void;
  clockInLatitude?: number | null;
  clockInLongitude?: number | null;
  clockOutLatitude?: number | null;
  clockOutLongitude?: number | null;
  id?: string;
}

export default function ViewLocationModal({
  open,
  onClose,
  clockInLatitude,
  clockInLongitude,
  clockOutLatitude,
  clockOutLongitude,
  id,
}: ViewLocationModalProps) {
  const initMap = async () => {
    const clockInMapElement = document.getElementById(`time-entry-clock-in-map-${id}`);
    const clockOutMapElement = document.getElementById(`time-entry-clock-out-map-${id}`);

    if (!clockInMapElement || !clockOutMapElement) return;

    if (clockInLatitude && clockInLongitude) {
      const clockInLocation = new google.maps.LatLng(clockInLatitude, clockInLongitude);
      const clockInMap = new google.maps.Map(clockInMapElement, {
        center: clockInLocation,
        zoom: 15,
      });

      new google.maps.Marker({
        position: clockInLocation,
        map: clockInMap,
      });
    }

    if (clockOutLatitude && clockOutLongitude) {
      const clockOutLocation = new google.maps.LatLng(clockOutLatitude, clockOutLongitude);
      const clockOutMap = new google.maps.Map(clockOutMapElement, {
        center: clockOutLocation,
        zoom: 15,
      });

      new google.maps.Marker({
        position: clockOutLocation,
        map: clockOutMap,
      });
    }
  };

  useEffect(() => {
    setTimeout(() => {
      initMap();
    }, 100);
  }, [open, clockInLatitude, clockInLongitude, clockOutLatitude, clockOutLongitude, id]);

  return (
    <Modal open={open} onClose={onClose}>
      <MDBox p={3}>
        <MDTypography variant="h6" mb={3}>
          Time Entry Location
        </MDTypography>
        {(!clockInLatitude || !clockInLongitude) && (!clockOutLatitude || !clockOutLongitude) ? (
          <MDTypography>No location data available</MDTypography>
        ) : (
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <MDTypography variant="subtitle2" mb={1}>
                Clock In Location
              </MDTypography>
              {!clockInLatitude || !clockInLongitude ? (
                <MDTypography variant="body2">No clock in location available</MDTypography>
              ) : (
                <div
                  id={`time-entry-clock-in-map-${id}`}
                  style={{
                    width: "100%",
                    height: "300px",
                    borderRadius: "10px",
                  }}
                />
              )}
            </Grid>
            <Grid item xs={12} md={6}>
              <MDTypography variant="subtitle2" mb={1}>
                Clock Out Location
              </MDTypography>
              {!clockOutLatitude || !clockOutLongitude ? (
                <MDTypography variant="body2">No clock out location available</MDTypography>
              ) : (
                <div
                  id={`time-entry-clock-out-map-${id}`}
                  style={{
                    width: "100%",
                    height: "300px",
                    borderRadius: "10px",
                  }}
                />
              )}
            </Grid>
          </Grid>
        )}
      </MDBox>
    </Modal>
  );
}
