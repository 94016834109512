import MDBox from "components/MDBox";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import MDTypography from "components/MDTypography";
import { InputLabel, Tooltip } from "@mui/material";
import { styled } from "@mui/material/styles";
import HelpIcon from "@mui/icons-material/Help";
import { PlaceHolderTypes } from "constants/PlaceholderTypes";
import React, { useState } from "react";
import PlaceHolderSelect from "components/PlaceHolderSelect/PlaceHolderSelect";

interface WYSIWYGProps {
  title: string;
  content: string;
  tooltip?: string;
  type?: PlaceHolderTypes | null;
  disabled?: boolean;
  onChange?: (content?: string) => void;
  onBlur: () => void;
  config?: any;
}
// Styled box to add breakpoint styling
const MDBoxStyled = styled(MDBox)(({ theme }) => ({
  display: "flex",
  gap: 2,
  alignItems: "center",
  flexDirection: "column",
  marginBottom: "15px",
  [theme.breakpoints.up("md")]: {
    flexDirection: "row",
  },
}));

export default function WYSIWYG({
  title,
  content,
  onChange,
  tooltip,
  type,
  disabled = false,
  onBlur,
  config = {},
}: Partial<WYSIWYGProps>) {
  const [editor, setEditor] = useState<any>();
  return (
    <MDBox>
      <MDBoxStyled>
        <MDBox sx={{ display: "flex", gap: 2, alignItems: "center" }}>
          {title && <InputLabel>{title}</InputLabel>}
          {tooltip && (
            <Tooltip
              title={
                <MDBox
                  dangerouslySetInnerHTML={{
                    __html: tooltip,
                  }}
                />
              }
            >
              <HelpIcon />
            </Tooltip>
          )}
        </MDBox>
        {type && !disabled && (
          <MDBox sx={{ marginLeft: "auto" }}>
            <PlaceHolderSelect
              type={type}
              value=""
              disabled={disabled}
              onChange={(e) => {
                editor.model.change((writer) => {
                  editor.model.insertContent(writer.createText(e.target.value));
                });
              }}
            />
          </MDBox>
        )}
      </MDBoxStyled>
      <CKEditor
        data={content}
        onReady={(editor) => {
          setEditor(editor);
        }}
        disabled={disabled}
        editor={ClassicEditor}
        onChange={(event, editor) => onChange(editor.getData())}
        onFocus={(event, editor) => onChange(editor.getData())}
        onBlur={onBlur}
        config={config}
      />
    </MDBox>
  );
}
