import { Icon, Menu, MenuItem } from "@mui/material";
import MDTypography from "components/MDTypography";
import { useCallback, useState } from "react";
import EditTimeEntryModal from "./EditTimeEntryModal";
import ViewLocationModal from "./ViewLocationModal";
import { useDeleteTimeEntry } from "hooks/timeTracking/useDeleteTimeEntry";
import ActionConfirmDialog from "components/ActionConfirmDialog/ActionConfirmDialog";
import useConfirmationDialog from "hooks/useConfirmationDialog";
import { UserPermissions } from "generated/graphql";
import { useCheckPermissions } from "hooks/authentication/useCheckPermissions";

export default function TimeEntryActionsCell({ value, row }) {
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLocationModalOpen, setIsLocationModalOpen] = useState(false);
  const [deleteTimeEntry] = useDeleteTimeEntry();

  const { hasPermissions } = useCheckPermissions();

  const handleMenuOpen = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleEditEntry = useCallback(() => {
    setIsModalOpen(true);
    handleMenuClose();
  }, [handleMenuClose]);

  const handleViewLocation = useCallback(() => {
    setIsLocationModalOpen(true);
    handleMenuClose();
  }, [handleMenuClose]);

  const acceptFn = useCallback(async () => {
    const result = await deleteTimeEntry({
      variables: { id: row.original.id },
    });
    return result.success;
  }, [deleteTimeEntry, row.original.id]);

  const cancelFn = useCallback(async () => {
    return true;
  }, []);

  const { open, setOpen, onAccept, onCancel } = useConfirmationDialog({
    acceptFn,
    cancelFn,
  });

  const handleDeleteEntry = useCallback(() => {
    setOpen(true);
    handleMenuClose();
  }, [handleMenuClose, setOpen]);

  return (
    <>
      <ActionConfirmDialog
        open={open}
        setOpen={setOpen}
        handleAccept={onAccept}
        handleCancel={onCancel}
        title="Delete Time Entry"
        content="Are you sure you want to delete this time entry?"
      />
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: "top", horizontal: "left" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        keepMounted
      >
        {hasPermissions(UserPermissions.MANAGE_TIME_TRACKING) && (
          <>
            <MenuItem onClick={handleEditEntry}>Edit Entry</MenuItem>
            <MenuItem onClick={handleDeleteEntry}>Delete Entry</MenuItem>
          </>
        )}
        <MenuItem onClick={handleViewLocation}>View Location</MenuItem>
      </Menu>
      <MDTypography
        color="secondary"
        display="flex"
        alignItems="center"
        justifyContent="center"
        width="100%"
        onClick={handleMenuOpen}
      >
        <Icon sx={{ cursor: "pointer", fontWeight: "bold" }}>more_vert</Icon>
      </MDTypography>
      <EditTimeEntryModal
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        entry={row.original}
      />
      <ViewLocationModal
        open={isLocationModalOpen}
        onClose={() => setIsLocationModalOpen(false)}
        clockInLatitude={row.original.clockInLatitude}
        clockInLongitude={row.original.clockInLongitude}
        clockOutLatitude={row.original.clockOutLatitude}
        clockOutLongitude={row.original.clockOutLongitude}
        id={row.original.id}
        key={row.original.id}
      />
    </>
  );
}
