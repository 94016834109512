import useDebounce from "hooks/useDebounce";
import React, { useState } from "react";

export interface Filtering {
  searchTerm: string;
  setSearchTerm: React.Dispatch<React.SetStateAction<string>>;
  debouncedSearchTerm: string;
}

export default function useDebouncedSearchTerm(defaultValue: string): Filtering {
  const [searchTerm, setSearchTerm] = useState<string | null>(defaultValue);

  const debouncedSearchTerm = useDebounce(searchTerm, 1000);

  return { searchTerm, setSearchTerm, debouncedSearchTerm };
}
