import { Grid } from "@mui/material";
import FormField from "components/FormField/FormField";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import PhoneInput from "components/PhoneInput/PhoneInput";
import StateSelect from "components/StateSelect/StateSelect";
import { CreatePlantInput } from "generated/graphql";
import { useFormContext, Controller } from "react-hook-form";
import { useEffect, useRef, useState } from "react";
import { getAddressObject } from "helpers/map";

const MainForm = () => {
  const [showAddressFields, setShowAddressFields] = useState<boolean>(false);
  const autoCompleteRef = useRef<google.maps.places.Autocomplete>();
  const geocoderRef = useRef<google.maps.Geocoder>();

  const {
    control,
    formState: { errors },
    register,
    setValue,
  } = useFormContext<CreatePlantInput>();

  useEffect(() => {
    geocoderRef.current = new google.maps.Geocoder();

    autoCompleteRef.current = new google.maps.places.Autocomplete(
      document.getElementById("addressLine1") as HTMLInputElement,
      {
        componentRestrictions: { country: ["USA", "CA"] },
        strictBounds: false,
        fields: ["address_components", "geometry"],
      }
    );

    if (autoCompleteRef.current) {
      autoCompleteRef.current.addListener("place_changed", async function () {
        const place = await autoCompleteRef.current.getPlace();
        if (place?.name) {
          setValue("addressLine1", "");
          return;
        }

        const addressParts = getAddressObject(place.address_components);
        setValue("addressLine1", `${addressParts.home} ${addressParts.street}`);
        setValue("addressCity", addressParts.city);
        setValue("addressState", addressParts.region);
        setValue("addressCountry", addressParts.country);
        setValue("addressZip", addressParts.postal_code);
        setShowAddressFields(true);

        if (place.geometry?.location) {
          const lat = place.geometry.location.lat();
          const lng = place.geometry.location.lng();
          setValue("latitude", lat.toString());
          setValue("longitude", lng.toString());
        }
      });
    }
  }, [setValue]);

  return (
    <>
      <MDBox mt={1}>
        <Grid container spacing={3}>
          <Grid item mb={2} xs={12} sm={6}>
            <FormField
              type="text"
              label="Name"
              placeholder="Name"
              error={errors.name}
              {...register("name", { required: true })}
            />
          </Grid>
          <Grid item mb={2} xs={12} sm={6}>
            <Controller
              control={control}
              name="phone"
              render={({ field }) => <PhoneInput {...field} label="Phone" error={errors.phone} />}
            />
          </Grid>
        </Grid>
      </MDBox>
      <MDBox mt={1}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <FormField
              type="text"
              label="Address Line 1"
              placeholder="Address Line 1"
              error={errors.addressLine1}
              id="addressLine1"
              {...register("addressLine1", { required: true })}
            />
            {!showAddressFields && (
              <MDButton
                sx={{
                  marginTop: "10px",
                }}
                variant="gradient"
                color="secondary"
                onClick={() => setShowAddressFields(true)}
              >
                Manually set address
              </MDButton>
            )}
          </Grid>
          {showAddressFields && (
            <>
              <Grid item mb={2} xs={12} sm={6}>
                <FormField
                  type="text"
                  label="Address Line 2"
                  placeholder="Address Line 2"
                  error={errors.addressLine2}
                  {...register("addressLine2")}
                />
              </Grid>
              <Grid item mb={2} xs={12} sm={6}>
                <FormField
                  type="text"
                  label="Address City"
                  error={errors.addressCity}
                  {...register("addressCity", { required: true })}
                />
              </Grid>
              <Grid item mb={2} xs={12} sm={6}>
                <FormField
                  type="text"
                  label="Address State"
                  error={errors.addressState}
                  {...register("addressState", { required: true })}
                />
              </Grid>
              <Grid item mb={2} xs={12} sm={6}>
                <FormField
                  type="text"
                  label="Address Zip"
                  error={errors.addressZip}
                  {...register("addressZip", { required: true })}
                />
              </Grid>
            </>
          )}
        </Grid>
      </MDBox>
    </>
  );
};

export default MainForm;
