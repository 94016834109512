import {
  Control,
  Controller,
  DeepRequired,
  FieldErrorsImpl,
  SubmitHandler,
  useForm,
  UseFormRegister,
  UseFormSetValue,
  UseFormWatch,
} from "react-hook-form";
import CountrySelect from "components/CountrySelect/CountrySelect";
import { getDefaultValues, schema } from "DDD/action-objects/AddressEdit";
import { zodResolver } from "@hookform/resolvers/zod";
import { UpdateOrganizationAddressInput, OrganizationAddress } from "generated/graphql";
import { Card, Checkbox, FormControlLabel, Grid } from "@mui/material";
import MDBox from "components/MDBox";
import FormField from "components/FormField/FormField";
import MDButton from "components/MDButton";
import StateSelect from "components/StateSelect/StateSelect";
import { useEffect, useRef, useState } from "react";
import { getAddressObject } from "helpers/map";

export interface AddressFormProps {
  onSubmit: SubmitHandler<UpdateOrganizationAddressInput>;
  loading: boolean;
  address: OrganizationAddress;
}

export default function AddressForm({ onSubmit, loading, address }: AddressFormProps) {
  const [showAddressFields, setShowAddressFields] = useState<boolean>(false);
  const autoCompleteRef = useRef<google.maps.places.Autocomplete>();
  const geocoderRef = useRef<google.maps.Geocoder>();

  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
    control,
    watch,
    setValue,
  } = useForm<UpdateOrganizationAddressInput>({
    resolver: zodResolver(schema),
    defaultValues: getDefaultValues(address),
  });

  useEffect(() => {
    geocoderRef.current = new google.maps.Geocoder();
    autoCompleteRef.current = new google.maps.places.Autocomplete(
      document.getElementById("line1") as HTMLInputElement,
      {
        componentRestrictions: { country: ["USA", "CA"] },
        strictBounds: false,
        fields: ["address_components", "geometry"],
      }
    );

    if (autoCompleteRef.current) {
      autoCompleteRef.current.addListener("place_changed", async function () {
        const place = await autoCompleteRef.current.getPlace();
        if (place?.name) {
          setValue("line1", "");
          return;
        }

        const addressParts = getAddressObject(place.address_components);
        setValue("line1", `${addressParts.home} ${addressParts.street}`);
        setValue("city", addressParts.city);
        setValue("state", addressParts.region);
        setValue("country", addressParts.country);
        setValue("zip", addressParts.postal_code);
        setShowAddressFields(true);

        if (place.geometry?.location) {
          const lat = place.geometry.location.lat();
          const lng = place.geometry.location.lng();
          setValue("latitude", lat.toString());
          setValue("longitude", lng.toString());
        }
      });
    }
  }, [setValue]);

  const geocodeAddress = async (address: string) => {
    if (!geocoderRef.current) return;

    try {
      const result = await geocoderRef.current.geocode({ address });
      if (result.results[0]?.geometry?.location) {
        const lat = result.results[0].geometry.location.lat();
        const lng = result.results[0].geometry.location.lng();
        setValue("latitude", lat.toString());
        setValue("longitude", lng.toString());
      }
    } catch (error) {}
  };

  return (
    <Card>
      <MDBox
        p={3}
        component="form"
        role="form"
        onSubmit={handleSubmit(async (rest: UpdateOrganizationAddressInput) => {
          if (!rest.latitude || !rest.longitude) {
            const fullAddress = `${rest.line1}, ${rest.city}, ${rest.state} ${rest.zip}, ${rest.country}`;
            await geocodeAddress(fullAddress);
          }

          // @ts-expect-error: FIX update types
          const { success, data: updatedAddress } = await onSubmit(rest);
          if (success) {
            reset(getDefaultValues(updatedAddress));
          }
        })}
      >
        <MDBox mt={1}>
          <Grid container spacing={3}>
            <input type="hidden" {...register("id")} />
            <Grid item xs={12}>
              <FormField
                type="text"
                label="Address Line 1"
                placeholder="Address Line 1"
                error={errors.line1}
                id="line1"
                {...register("line1")}
              />
              {!showAddressFields && (
                <MDButton
                  sx={{
                    marginTop: "10px",
                  }}
                  variant="gradient"
                  color="secondary"
                  onClick={() => setShowAddressFields(true)}
                >
                  Manually set address
                </MDButton>
              )}
            </Grid>
            {(showAddressFields || address) && (
              <>
                <Grid item xs={12}>
                  <FormField
                    type="text"
                    label="Address Line 2"
                    placeholder="Address Line 2"
                    error={errors.line2}
                    {...register("line2")}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormField
                    type="text"
                    label="Address City"
                    error={errors.city}
                    {...register("city")}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormField
                    type="text"
                    label="Address State"
                    error={errors.state}
                    {...register("state")}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormField
                    type="text"
                    label="Address Zip"
                    error={errors.zip}
                    {...register("zip")}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <CountrySelect
                    name="country"
                    label="Address Country"
                    defaultValue="US"
                    required
                    control={control}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControlLabel
                    control={
                      <Controller
                        name={"isPrimary"}
                        control={control}
                        render={({ field }) => (
                          <Checkbox
                            {...field}
                            checked={field.value}
                            onChange={(e) => field.onChange(e.target.checked)}
                          />
                        )}
                      />
                    }
                    label="Primary Address"
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <MDBox display="flex" justifyContent="flex-end">
                    <MDButton variant="gradient" color="success" type="submit" disabled={loading}>
                      Save
                    </MDButton>
                  </MDBox>
                </Grid>
              </>
            )}
          </Grid>
        </MDBox>
      </MDBox>
    </Card>
  );
}
