import { Grid } from "@mui/material";
import MDButton from "components/MDButton";
import { Controller, FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  CreateCompanyInput,
  CustomCreateProposalMutationVariables,
  ProposalSource,
} from "generated/graphql";
import CustomSelect from "components/Shared/CustomSelect/CustomSelect";
import { enumToValueOptions } from "utils/enums/enumToValueOptions";
import { createProposalSchema, getDefaultValues } from "DDD/action-objects/ProposalCreate";
import MDBox from "components/MDBox";
import CompanyOptions from "modules/companies/CompanyOptions";
import AutoComplete from "modules/AutoComplete/AutoComplete";
import useModalState from "hooks/useModalState";
import Modal from "modules/Modal/Modal";
import MDTypography from "components/MDTypography";
import CreateCompany from "modules/companies/CreateCompany/CreateCompany";
import useCreateCompany from "hooks/companies/useCreateCompany";
import { useCallback, useEffect, useState } from "react";
import ScrollAsyncAutocomplete from "components/ScrollAsyncAutocomplete/ScrollAsyncAutocomplete";
import { useGetCompanyLazy } from "hooks/companies/useGetCompanyLazy";

export default function CreateProposalForm({
  onSubmit,
  companyInitialId,
}: {
  onSubmit: SubmitHandler<CustomCreateProposalMutationVariables>;
  companyInitialId?: string;
}) {
  const { open, onClose, onOpen } = useModalState();
  const [triggerRefetch, setTriggerRefetch] = useState(false);
  const methods = useForm<CustomCreateProposalMutationVariables>({
    resolver: zodResolver(createProposalSchema),
    defaultValues: getDefaultValues({ companyId: companyInitialId }),
  });

  const {
    formState: { errors },
  } = methods;

  const companyId = methods.watch("companyId");

  const [createCompany, { loading: createCompanyLoading, getData, isSuccess }] = useCreateCompany();

  const onCreateCompanySubmit = useCallback(
    async (input: CreateCompanyInput) => {
      const result = await createCompany({ variables: { input } });
      const success = isSuccess(result.data);
      const data = getData(result);
      if (success) {
        onClose();
        setTriggerRefetch(true);
      }
      return { success, data };
    },
    [createCompany]
  );

  const [getCompany, { company }] = useGetCompanyLazy();

  useEffect(() => {
    getCompany({ id: companyId });
  }, [getCompany, companyId]);

  return (
    <>
      <FormProvider {...methods}>
        <MDBox
          p={3}
          component="form"
          role="form"
          onSubmit={methods.handleSubmit(async (values) => {
            const result = await onSubmit(values);
            // @ts-expect-error: FIX update types
            if (result.success) {
              methods.reset();
            }
          })}
        >
          <Grid container spacing={3}>
            <CompanyOptions
              triggerRefetch={triggerRefetch}
              selectedCompany={company}
              setTriggerRefetch={(trigger) => setTriggerRefetch(trigger)}
            >
              {({ companyOptions, companyContactOptions, loading, search, pagination }) => (
                <>
                  <Grid item xs={12} md={6}>
                    <Controller
                      name="companyId"
                      control={methods.control}
                      render={({ field, fieldState: { error } }) => (
                        <>
                          <ScrollAsyncAutocomplete
                            {...field}
                            value={companyOptions?.find((x) => x.value === field.value)}
                            options={companyOptions ?? []}
                            loading={loading}
                            label="Select Client"
                            search={search}
                            onLoadMore={pagination.loadMore}
                            hasMore={pagination.hasNextPage}
                            error={errors?.companyId?.message}
                          />
                        </>
                      )}
                    />
                    <MDBox mt={2}>
                      <MDButton
                        type="button"
                        size="small"
                        variant="gradient"
                        color="secondary"
                        onClick={() => {
                          onOpen();
                        }}
                      >
                        Create Client
                      </MDButton>
                    </MDBox>
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <Controller
                      name="contactId"
                      control={methods.control}
                      render={({ field, fieldState: { error } }) => (
                        <AutoComplete
                          options={companyId ? companyContactOptions[companyId] : []}
                          disabled={!companyId || loading}
                          field={field}
                          loading={loading}
                          error={error}
                          label={`Select Contact ${companyId ? "" : "(Select Client First)"}`}
                        />
                      )}
                    />
                  </Grid>
                </>
              )}
            </CompanyOptions>
            <Grid item xs={12} md={6}>
              <CustomSelect
                control={methods.control}
                name="source"
                data={enumToValueOptions(ProposalSource)}
                label={"Proposal Source"}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <MDButton variant="gradient" color="info" type="submit">
                Continue
              </MDButton>
            </Grid>
          </Grid>
        </MDBox>
      </FormProvider>
      <Modal open={open} onClose={onClose} styles={{ overflow: "auto" }}>
        <MDTypography variant="h6" p={3}>
          Create New Contact
        </MDTypography>
        <CreateCompany onSubmit={onCreateCompanySubmit} loading={createCompanyLoading} />
      </Modal>
    </>
  );
}
