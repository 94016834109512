// @ts-nocheck

import React, { useEffect, useState, useRef } from "react";
import "./Map.css";
import "leaflet/dist/leaflet.css";
import { MapContainer, FeatureGroup } from "react-leaflet";
import ReactLeafletGoogleLayer from "react-leaflet-google-layer";
import GeomanWrapper, { ColoredDivIcon, LayerInformationIcon, NumberedDivIcon } from "./Geoman";
import type { FeatureCollection } from "geojson";
import Controls from "./Controls";
import GuidedControls from "./GuidedControls";
import * as L from "leaflet";
import "leaflet-geometryutil";
import { MAP_MODE } from "types/types";
import * as turf from "@turf/turf";
import { MapMeasurementTotals, MapAddress, MapOutput } from "interfaces/interfaces";
import {
  addLayerFeature,
  addLayerLabelLine,
  addLayerLineLengths,
  calculateDraggedMarkerLabelPosition,
  checkForIntersects,
  clearLayer,
  getAddressObject,
  getCenterOfLayer,
  getLayerLabelPosition,
  getLayerTooltipContent,
  removeLayerLabelLine,
  removeLayerLabels,
  repositionLayerLabelLine,
  toggleDrawMode,
  updateDrawingLineToolTipContent,
} from "helpers/map";
import { meterConversions } from "constants/meterConversions";
import { ProductMeasurementJob } from "generated/graphql";

const defaultColor = "#f44336";
export type MapCoords = { lng: string; lat: string };
export default function MapComponent({
  createIslandConfirmation,
  onSave,
  initialArea,
  initialFeatures,
  initialCenter,
  readonly,
  isTouch,
  hideLocation = false,
  guidedControls,
  goBack = false,
  showTools,
  close,
}: {
  createIslandConfirmation: (callback: () => void) => boolean;
  onSave: (data: MapOutput) => void | null;
  initialArea?: FeatureCollection | null;
  initialFeatures?: FeatureCollection | null;
  initialCenter: [number, number] | null;
  readonly: boolean;
  isTouch: boolean;
  hideLocation?: boolean;
  guidedControls: boolean;
  goBack?: boolean;
  showTools?: [keyof typeof ProductMeasurementJob];
  close?: () => void;
}) {
  const featureGroup = React.useRef<L.FeatureGroup>(null);
  const initialAreaFeatureGroup = React.useRef<L.FeatureGroup>(null);
  const defaultLayerColor = useRef<string>(defaultColor);
  const mapMode = useRef<MAP_MODE | null>(null);
  const editingLayer = useRef<object>({
    layer: null,
    parent: null,
  });

  const [editingLayerType, setEditingLayerType] = useState<string | null>();
  const [map, setMap] = useState<L.Map>();
  const [currentDrawShape, setCurrentDrawShape] = React.useState<L.PM.SUPPORTED_SHAPES | NULL>(
    null
  );
  const [isEditing, setIsEditing] = React.useState<boolean>(false);
  const [isDragging, setIsDragging] = React.useState<boolean>(false);
  const [layerColor, setLayerColor] = React.useState<string>(defaultColor);
  const [layerName, setLayerName] = React.useState<string | null>();
  const [mapTotals, setMapTotals] = React.useState<MapMeasurementTotals>({
    area: {
      feet: 0,
      yards: 0,
    },
    perimeter: {
      feet: 0,
      yards: 0,
    },
  });
  const [layerShowLineLength, setLayerShowLineLength] = React.useState<boolean>(false);
  const [selectedAddress, setSelectedAddress] = React.useState<MapAddress | null>();

  const addLayerEvents = (layer: L.Layer): void => {
    layer.on("pm:enable", () => {
      if (isTouch) {
        if (editingLayer.current.layer instanceof L.Marker) {
          toggleDrag();
          editingLayer.current.layer.getIcon().unhighlight(editingLayer.current.layer);

          toggleDragLabel(true);
          setIsDragging(false);
          setIsEditing(false);
        }
      }

      editingLayer.current.layer?.pm?.disable();
      editingLayer.current.layer = layer;
      mapMode.current = "editing";
      setEditingLayerType("layer");
      setLayerName(layer.feature?.properties?.labelText ?? "");
      setLayerColor(layer.feature?.properties?.color);
      setLayerShowLineLength(layer.feature?.properties?.showLineLengths ?? false);
      setIsEditing(true);
      clearLayer(map, layer);

      if (editingLayer.current.layer instanceof L.Marker) {
        editingLayer.current.layer.getIcon().highlight(editingLayer.current.layer);
      }
    });

    layer.on("pm:disable", () => {
      if (!layer._removing) {
        addLayerLineLengths(map, editingLayer.current.layer as L.Layer);
        addLayerLabel(layer, isTouch);

        if (editingLayer.current.layer instanceof L.Marker) {
          editingLayer.current.layer.getIcon().unhighlight(editingLayer.current.layer);
        }
      }
      setIsEditing(false);
      editingLayer.current.layer = null;
      mapMode.current = null;

      setMapTotals(calculateTotalAreaAndPerimeter());
    });

    layer.on("click", (le) => {
      if (mapMode.current === "drawing") {
        return;
      }

      L.DomEvent.stopPropagation(le);

      if (isTouch) {
        // unhighlight previous layer
        if (editingLayer.current.layer instanceof L.Marker) {
          editingLayer.current.layer.getIcon().unhighlight(editingLayer.current.layer);
        }

        // if it's a touch device, disable drag by default
        layer.pm.toggleEdit({ draggable: false, preventMarkerRemoval: true });
      } else {
        if (layer._dragging) {
          return;
        }

        layer.pm.toggleEdit({
          allowSelfIntersection: false,
        });

        if (layer instanceof L.Marker) {
          le.target.dragging.enable();
        }
      }
    });

    layer.on("dblclick", (le) => {
      L.DomEvent.stopPropagation(le);
    });

    layer.on("pm:cut", (le) => {
      clearLayer(map, le.originalLayer);
      addLayerLineLengths(map, le.layer);
      addLayerLabel(le.layer, isTouch);
      addLayerEvents(le.layer);

      setMapTotals(calculateTotalAreaAndPerimeter());
    });

    layer.on("remove", () => {
      layer._removing = true;
      clearLayer(map, layer);

      setMapTotals(calculateTotalAreaAndPerimeter());
    });

    if (!(layer instanceof L.Marker)) {
      layer.on("mouseover", () => {
        layer?.setStyle({
          weight: 5,
        });
      });

      layer.on("mouseout", () => {
        layer?.setStyle({
          weight: 3,
        });
      });
    } else if (layer instanceof L.Marker) {
      // only add pm:dragstart and pm:dragend event handlers for non touch devices
      if (!isTouch) {
        layer.on("pm:dragstart", (e) => {
          layer._previousLatlng = e.target._latlng;
          layer._dragging = true;
          layer.getIcon().unhighlight(layer);
        });

        layer.on("pm:dragend", (e) => {
          layer._dragging = false;
          if (layer.feature.properties.labelLatLng) {
            layer.feature.properties.labelLatLng = calculateDraggedMarkerLabelPosition(
              layer,
              e.target._latlng
            );
          }
          layer.getIcon().highlight(layer);
        });
      }

      layer.on("mouseover", () => {
        if (layer._dragging) {
          return;
        }

        layer.getIcon().highlight(layer);
      });

      layer.on("mouseout", () => {
        if (editingLayer.current.layer != layer) {
          layer.getIcon().unhighlight(layer);
        }
      });
    }

    layer.on("pm:edit", () => setMapTotals(calculateTotalAreaAndPerimeter()));
  };

  const handleChange = (e): void => {
    if (e.type === "pm:create") {
      const layer = e.layer;

      if (
        layer instanceof L.Polygon &&
        checkForIntersects(map, featureGroup.current, layer, createIslandConfirmation)
      ) {
        return;
      }

      addLayerFeature(layer, defaultLayerColor.current);
      addLayerEvents(layer);

      if (layer instanceof L.Polyline) {
        layer.feature.properties.showLineLengths = layerShowLineLength;

        addLayerLineLengths(map, layer);
        addLayerLabel(layer, isTouch);
      }

      setMapTotals(calculateTotalAreaAndPerimeter());
    }
  };

  const generateGeojson = (): FeatureCollection => {
    const newGeo: FeatureCollection = {
      type: "FeatureCollection",
      features: [],
    };
    const allLayers = featureGroup.current.getLayers().length
      ? featureGroup.current.getLayers()
      : initialAreaFeatureGroup.current.getLayers();
    if (!allLayers) {
      return newGeo;
    }

    allLayers.forEach((layer) => {
      if (layer.options.pmIgnore) {
        return true;
      }

      if (layer.options.icon instanceof LayerInformationIcon) {
        return true;
      }

      if (layer?.label) {
        layer.feature.properties.labelLatLng = layer?.label?._latlng;
      }

      if (
        layer instanceof L.Circle ||
        layer instanceof L.CircleMarker ||
        layer instanceof L.Point
      ) {
        const { lat, lng } = layer.getLatLng();
        newGeo.features.push({
          type: "Feature",
          properties: {
            radius: layer.getRadius(),
          },
          geometry: {
            type: "Point",
            coordinates: [lng, lat],
          },
        });
        return true;
      }

      if (
        layer instanceof L.Marker ||
        layer instanceof L.Polygon ||
        layer instanceof L.Rectangle ||
        layer instanceof L.Polyline
      ) {
        newGeo.features.push(layer.toGeoJSON());
        return true;
      }
    });

    return newGeo;
  };

  const calculateTotalAreaAndPerimeter = (): MapMeasurementTotals => {
    const area = {
      feet: 0,
      yards: 0,
    };
    const perimeter = {
      feet: 0,
      yards: 0,
    };

    featureGroup.current?.eachLayer((layer) => {
      if (layer._removing) {
        return true;
      }

      if (layer instanceof L.Polygon) {
        area.feet += Math.ceil(turf.area(layer.toGeoJSON()) * meterConversions.squareFeet);

        area.yards += Math.ceil(turf.area(layer.toGeoJSON()) * meterConversions.squareYards);

        return true;
      }

      if (layer instanceof L.Polyline) {
        const latLngArray = layer.getLatLngs().map((latlng) => L.GeoJSON.latLngToCoords(latlng));
        perimeter.feet += Math.ceil(
          turf.length(turf.lineString(latLngArray), {
            units: "feet",
          })
        );

        perimeter.yards += Math.ceil(
          turf.length(turf.lineString(latLngArray), {
            units: "yards",
          })
        );

        return true;
      }
    });

    return {
      area,
      perimeter,
    };
  };

  const onSaveClick = (addressOverride?: string): void => {
    if (guidedControls && !selectedAddress?.line1) {
      alert("Invalid address, missing Address Line 1");
      return;
    }
    onSave({
      geojson: generateGeojson(),
      measurements: mapTotals,
      address: selectedAddress,
      addressOverride,
    });
  };

  const toggleDrag = () => {
    if (isDragging) {
      editingLayer.current.layer._dragging = false;
      editingLayer.current?.layer?.dragging?.disable();

      setIsDragging(false);
      return;
    }

    editingLayer.current.layer._previousLatlng = editingLayer.current.layer._latlng;
    editingLayer.current.layer._dragging = true;
    editingLayer.current?.layer?.dragging?.enable();
    setIsDragging(true);
  };

  const deleteEditingLayer = () => {
    if (!editingLayer.current.layer) {
      return;
    }

    editingLayer.current.layer.removeFrom(map.pm._getContainingLayer());
    editingLayer.current.layer?.remove();
    editingLayer.current.layer = null;
    setEditingLayerType(null);
  };

  const clearMap = (): void => {
    deleteEditingLayer();

    const layers = featureGroup.current.getLayers();
    for (const layer of layers) {
      layer.removeFrom(map.pm._getContainingLayer());
      layer.remove();
    }
  };

  const toggleDragLabel = (active: boolean | null) => {
    const draggingEnabled: boolean = active ?? isDragging;
    for (const layer of featureGroup.current?.getLayers()) {
      if (layer?.label) {
        // if isDragging = true, user is clicking the 'stop drag label' button
        if (draggingEnabled) {
          layer.label.dragging.disable();
        } else {
          layer.label.dragging.enable();
        }
      }
    }

    setIsDragging(!draggingEnabled);
  };

  const selectPlace = (place: any, coords?: MapCoords) => {
    if (place === null) {
      setSelectedAddress(null);
      return;
    }

    const location = place.geometry.location;
    const latLon = new L.LatLng(location.lat(), location.lng());
    const bounds = latLon.toBounds(100); // 500 = metres
    map.panTo(latLon).fitBounds(bounds);
    const addressParts = getAddressObject(place.address_components);
    if (
      guidedControls &&
      ((!addressParts.home && !addressParts.street) || !location.lat() || !location.lng())
    ) {
      setSelectedAddress(null);
      alert("Invalid Address, Line 1 is required");
      return;
    }
    setSelectedAddress({
      line1: `${addressParts.home} ${addressParts.street}`,
      city: addressParts?.city,
      state: addressParts?.region,
      zip: addressParts?.postal_code,
      country: addressParts?.country,
      lat: location.lat(),
      lng: location.lng(),
    });
  };

  const addLayerLabel = (layer: L.Layer, isTouch: boolean): void => {
    const content = getLayerTooltipContent(layer);
    if (!content) {
      if (layer.label) {
        removeLayerLabels(map, layer);
      }

      return;
    }

    const position = getLayerLabelPosition(layer);
    if (!position) {
      return;
    }

    const marker = L.marker(position, {
      icon: new LayerInformationIcon({
        content,
        color: layer.feature?.properties?.color,
        onCancel: () => {
          layer.feature.properties.labelLatLng = false;

          removeLayerLabels(map, layer);
          removeLayerLabelLine(map, layer);
          if (isTouch) {
            toggleDragLabel(false);
            setIsDragging(false);

            editingLayer.current.layer?.pm?.enable();
            editingLayer.current.layer = null;
            editingLayer.current.parent = null;
            mapMode.current = "null";
            setEditingLayerType(null);
            setIsEditing(false);
            editingLayer.current.layer.getIcon().unhighlight(editingLayer.current.layer);
          }
        },
      }),
      pane: "tooltipPane",
      pmIgnore: false,
      snapIgnore: true,
    }).addTo(map);

    layer.label = marker;

    // if it's not a touch device, enable drag for layer labels
    if (!isTouch) {
      marker?.pm.enableLayerDrag();
    }

    marker?.on("click", (e) => {
      L.DomEvent.stopPropagation(e);

      if (isTouch) {
        if (editingLayer.current.layer) {
          if (editingLayer.current.layer instanceof L.Marker) {
            editingLayer.current.layer.getIcon().unhighlight(editingLayer.current.layer);
          }
          toggleDragLabel(true);
          setIsDragging(false);
        }

        editingLayer.current.layer?.pm?.disable();
        editingLayer.current.layer = marker;
        editingLayer.current.parent = layer;
        mapMode.current = "editing";
        setEditingLayerType("label");
        setIsEditing(true);
        editingLayer.current.layer.getIcon().highlight(editingLayer.current.layer);
      }
    });

    layer.label?.on("dblclick", (e) => {
      L.DomEvent.stopPropagation(e);

      layer.pm.enable();
    });

    let dragEventName = "pm:drag";
    let dragEndEventName = "pm:dragend";

    // this event handler is only for touch devices
    if (isTouch) {
      dragEndEventName = "dragend";
    }

    layer.label?.on(dragEndEventName, (e) => {
      layer.feature.properties.labelLatLng = e.target._latlng;
    });

    if (!(layer instanceof L.Marker)) {
      layer.label?.on("mouseover", () => {
        layer?.setStyle({
          weight: 5,
        });
      });

      layer.label?.on("mouseout", () => {
        layer?.setStyle({
          weight: 3,
        });
      });
    } else if (layer instanceof L.Marker) {
      addLayerLabelLine(map, layer, position);

      // this event handler is only for touch devices
      if (isTouch) {
        dragEventName = "drag";
      }

      layer.label?.on(dragEventName, (e) => {
        repositionLayerLabelLine(layer, getCenterOfLayer(layer), [
          e.target._latlng.lat,
          e.target._latlng.lng,
        ]);
      });
    }
  };

  useEffect(() => {
    if (!map) {
      return;
    }

    if (editingLayer.current.layer) {
      if (editingLayer.current.layer instanceof L.Marker) {
        if (editingLayer.current.layer.feature) {
          editingLayer.current.layer.feature.properties.color = layerColor;
          editingLayer.current.layer.getIcon().changeColor(layerColor, editingLayer.current.layer);
        }
        return;
      }

      editingLayer.current.layer?.setStyle({
        color: layerColor,
        weight: 3,
        fillColor: layerColor,
        fillOpacity: 0.3,
      });
      editingLayer.current.layer.feature.properties.color = layerColor;

      return;
    }

    if (currentDrawShape === "Marker") {
      map.pm.Draw.Marker.options.markerStyle.icon.changeColor(
        layerColor,
        map.pm.Draw.Marker._layer
      );
    }

    defaultLayerColor.current = layerColor;
    map.pm.setGlobalOptions({
      markerEditable: false,

      templineStyle: {
        ...map.pm.getGlobalOptions().templineStyle,
        color: layerColor,
        fillColor: layerColor,
      },
      hintlineStyle: {
        ...map.pm.getGlobalOptions().hintlineStyle,
        color: layerColor,
        fillColor: layerColor,
      },
    });

    map.pm.setPathOptions(
      {
        color: layerColor,
        weight: 3,
        fillColor: layerColor,
        fillOpacity: 0.3,
      },
      {
        merge: true,
      }
    );
    if (mapMode.current === "drawing") {
      map.pm.disableDraw();
      map.pm.enableDraw(currentDrawShape, {
        snappable: false,
      });
    }
  }, [layerColor, map, currentDrawShape]);

  useEffect(() => {
    if (!editingLayer.current.layer || editingLayerType == "label") {
      return;
    }

    if (!layerName || layerName === "") {
      delete editingLayer.current.layer.feature.properties.labelText;
      return;
    }
    editingLayer.current.layer.feature.properties.labelText = layerName;
  }, [layerName]);

  useEffect(() => {
    if (!map) {
      return;
    }
    if (initialArea) {
      initialAreaFeatureGroup.current = featureGroup.current;
      initialAreaFeatureGroup.current.addTo(map);

      L.geoJSON(initialArea).eachLayer((layer) => {
        if (layer instanceof L.Marker) {
          layer.setIcon(
            new ColoredDivIcon({
              color: "#6EACDA",
            })
          );
        }
        initialAreaFeatureGroup.current.addLayer(layer);
        if (layer && layer.setStyle) {
          layer?.setStyle({
            weight: 2,
            fill: true,
          });
        }
        addLayerEvents(layer);
      });

      if (!initialFeatures) {
        map.fitBounds(initialAreaFeatureGroup.current.getBounds());
      }
    }
    if (initialFeatures) {
      featureGroup.current = L.featureGroup();
      featureGroup.current.addTo(map);
      L.geoJSON(initialFeatures, {
        onEachFeature: (feature, layer) => {
          if (layer instanceof L.Marker) {
            layer.setIcon(
              new ColoredDivIcon({
                color: feature.properties.color,
              })
            );
          }

          if (layer instanceof L.Polyline) {
            layer?.setStyle({
              color: feature.properties.color,
              weight: 3,
              fillColor: feature.properties.color,
              fillOpacity: 0.3,
            });
          }
          addLayerLineLengths(map, layer);
          addLayerLabel(layer, isTouch);
          addLayerEvents(layer);
        },
      }).eachLayer((layer) => {
        featureGroup.current.addLayer(layer);
      });

      setMapTotals(calculateTotalAreaAndPerimeter());
      map.fitBounds(featureGroup.current.getBounds());
    }

    if (!initialFeatures && !initialArea && initialCenter) {
      const latLon = new L.LatLng(...initialCenter);
      const bounds = latLon.toBounds(100); // 500 = metres
      map.panTo(latLon).fitBounds(bounds);
    }

    map.on("pm:keyevent", (e) => {
      if (e.eventType === "keydown") {
        return;
      }

      if (e.event.code === "Backspace") {
        deleteEditingLayer();
      }

      if (e.event.code === "Escape") {
        if (editingLayer.current.layer) {
          editingLayer.current.layer.pm.disable();
          return;
        }

        if (mapMode.current === "drawing") {
          map.pm.disableDraw();
          return;
        }
      }
    });

    map.on("pm:dragend", (e) => {
      editingLayer.current.layer = e.layer;
      e.layer.pm.enable();
    });

    map.on("pm:dragstart", (e) => {
      e.layer.pm.disable();
    });
    map.on("click", (e) => {
      // if it's a touch device, this will calculate the marker's label new latlng whenever user clicks on the map, and all toggles will be false
      if (isTouch) {
        if (editingLayer.current.layer instanceof L.Marker) {
          if (
            editingLayer.current.layer?._previousLatlng &&
            editingLayer.current.layer?.feature?.properties?.labelLatLng
          ) {
            editingLayer.current.layer.feature.properties.labelLatLng =
              calculateDraggedMarkerLabelPosition(
                editingLayer.current.layer,
                editingLayer.current.layer._latlng
              );
          }
          toggleDrag();
          editingLayer.current.layer.getIcon().unhighlight(editingLayer.current.layer);
        }

        toggleDragLabel(true);
        setIsDragging(false);
        setIsEditing(false);
      }

      editingLayer.current.layer?.pm?.disable();
      editingLayer.current.layer = null;
      editingLayer.current.parent = null;
      setEditingLayerType(null);
    });

    map.on("pm:drawstart", ({ workingLayer, shape }) => {
      setCurrentDrawShape(shape);
      mapMode.current = "drawing";

      if (workingLayer instanceof L.Polyline) {
        if (shape === "Polygon") {
          map.pm.Draw.Polygon._hintMarker.bindTooltip("", {
            permanent: false,
            offset: L.point(0, 10),
            direction: "bottom",
            opacity: 1,
          });

          // map.pm.Draw.Polygon._hintMarker.on(
          //   'move',
          //   updateDrawingPolygonToolTipContent
          // );
          return;
        }

        if (shape === "Line") {
          map.pm.Draw.Line._hintMarker.bindTooltip("", {
            permanent: false,
            offset: L.point(0, 10),
            direction: "bottom",
            opacity: 1,
          });

          map.pm.Draw.Line._hintMarker.on("move", updateDrawingLineToolTipContent);
          return;
        }
      }
    });

    map.on("pm:drawend", () => {
      setCurrentDrawShape(null);
      mapMode.current = null;
    });

    return () => {
      map.off("pm:dragend");
      map.off("pm:dragstart");
      map.off("click");
      map.off("pm:keyevent");
      map.off("pm:drawstart");
      map.off("pm:drawend");
    };
  }, [map]);

  useEffect(() => {
    if (!editingLayer.current.layer) {
      return;
    }
    if (map) {
      map.pm.setGlobalOptions({ snappable: false });
    }

    if (editingLayer.current.layer?.feature?.properties) {
      editingLayer.current.layer.feature.properties = {
        ...editingLayer.current.layer.feature.properties,
        showLineLengths: layerShowLineLength,
      };
    }
  }, [layerShowLineLength, map]);
  const setFeatureGroup = (fg: L.FeatureGroup) => {
    if (featureGroup.current) {
      featureGroup.current.addLayer(fg.getLayers()[0]);
      featureGroup.current.addTo(map);
    }
  };
  return (
    <>
      <MapContainer
        id="map-container"
        center={[39.8097343, -98.5556199]}
        zoom={5}
        scrollWheelZoom={true}
        zoomControl={false}
        boxZoom={false}
        style={{ minWidth: "100%", minHeight: "100%" }}
      >
        <FeatureGroup ref={featureGroup}>
          <GeomanWrapper setMap={setMap} handleChange={handleChange} />
        </FeatureGroup>
        <ReactLeafletGoogleLayer useGoogMapsLoader={false} type={"hybrid"} />
      </MapContainer>
      {map && !readonly && guidedControls && (
        <GuidedControls
          map={map}
          featureGroup={setFeatureGroup}
          currentDrawShape={currentDrawShape}
          mapMode={mapMode.current}
          color={layerColor}
          setColor={setLayerColor}
          isEditing={isEditing}
          name={`${layerName}`}
          setName={setLayerName}
          showSave={onSave !== null}
          onSave={onSaveClick}
          isDragging={isDragging}
          toggleDrag={toggleDrag}
          deleteLayer={deleteEditingLayer}
          total={mapTotals}
          showLineLength={layerShowLineLength}
          setShowLineLength={setLayerShowLineLength}
          editingLayer={editingLayer.current}
          editingLayerType={editingLayerType}
          toggleDrawMode={toggleDrawMode}
          isTouch={isTouch}
          selectedAddress={selectedAddress}
          selectPlace={selectPlace}
          clearMap={clearMap}
          close={close}
        />
      )}
      {map && !readonly && !guidedControls && (
        <Controls
          map={map}
          featureGroup={featureGroup.current}
          currentDrawShape={currentDrawShape}
          mapMode={mapMode.current}
          color={layerColor}
          setColor={setLayerColor}
          isEditing={isEditing}
          name={`${layerName}`}
          setName={setLayerName}
          showSave={onSave !== null}
          onSave={onSaveClick}
          isDragging={isDragging}
          toggleDrag={toggleDrag}
          deleteLayer={deleteEditingLayer}
          total={mapTotals}
          showLineLength={layerShowLineLength}
          setShowLineLength={setLayerShowLineLength}
          editingLayer={editingLayer.current}
          editingLayerType={editingLayerType}
          toggleDrawMode={toggleDrawMode}
          isTouch={isTouch}
          selectedAddress={selectedAddress}
          selectPlace={selectPlace}
          clearMap={clearMap}
          hideLocation={hideLocation}
          goBack={goBack}
          showTools={showTools}
          close={close}
        />
      )}
    </>
  );
}
